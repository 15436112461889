import React, { useState } from 'react';
import {Alert, Button, Col, Form, Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import worldImg from '../assets/images/bg-shapes/bg1.png';
import mailSentSvg from '../assets/svg/illustrations/mail-sent-amico.svg';
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/Seo';

const ContactPage = () => {
  const { i18n, t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setIsSubmitting(true);

      try {
        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());

        await fetch('https://getform.io/f/b0b9f056-c298-4402-a2f3-bfdb9963b85e', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          body: JSON.stringify(formDataObj),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
        setSubmitted(true);
      } catch (err) {
        setError(t('contactPage.error'));
      }

      setIsSubmitting(false);
    }

    setValidated(true);
  };

  return (
    <>
      <SEO
        title={t('navigation.contact')}
        lang={i18n.language}
        description={t('meta.contact.description')}
      />

      <section>
        <div
          className="position-relative bg-dark space-top-3 space-top-lg-2"
          style={{
            backgroundImage: `url(${worldImg})`,
            backgroundPositionY: '50px',
            backgroundPositionX: 'right',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="container position-relative z-index-2">
            <div className="row justify-content-lg-between align-items-md-center">
              <div className="col-md-6 col-lg-5 mb-5 mb-md-0 space-md-3 space-2">
                <div className="mb-4">
                  <h1 className="display-4 text-white">{t('contactPage.title')}</h1>
                  <p style={{ color: 'rgba(255, 255, 255, 0.7)' }}>{t('contactPage.subtitle')}</p>
                </div>
              </div>
            </div>
          </div>
          <figure>
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
            >
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
            </svg>
          </figure>
        </div>
      </section>

      <section className="container space-2 space-bottom-lg-3">
        <div className="row justify-content-lg-center">
          <div className="col-lg-6 d-none d-lg-inline-block align-self-center">
            <figure className="w-100 text-center">
              <img className="img-fluid w-lg-85" src={mailSentSvg} alt="mail illustration" />
            </figure>
          </div>
          <div className="col-lg-6">
            {error && (
              <Alert variant="danger">
                <b>Oops!</b> {error}
              </Alert>
            )}
            {submitted ? (
              <div className="d-flex align-items-center h-100">
                <p className="h4 text-center">{t('contactPage.submittedMessage')}</p>
              </div>
            ) : (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="validationName">
                    <Form.Label>{t('contactPage.labelName')}</Form.Label>
                    <Form.Control required type="text" name="name" placeholder="Joe Doe" />
                    <Form.Control.Feedback type="invalid">
                      {t('contactPage.requiredName')}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationEmail">
                    <Form.Label>{t('contactPage.labelEmail')}</Form.Label>
                    <Form.Control required type="email" name="email" placeholder="joe@doe.com" />
                    <Form.Control.Feedback type="invalid">
                      {t('contactPage.requiredEmail')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="validationProject">
                    <Form.Label>{t('contactPage.labelProject')}</Form.Label>
                    <Form.Control as="select" name="project" defaultValue="Select project type">
                      <option>Web Application</option>
                      <option>AWS and Serverless Application</option>
                      <option>SaaS Application</option>
                      <option>Other</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="validationMessage">
                    <Form.Label>{t('contactPage.labelMessage')}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={4}
                      required
                      placeholder={t('contactPage.labelMessage2')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t('contactPage.requiredMessage')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Button type="submit" className="transition-3d-hover" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner
                          className="mb-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      />
                      &nbsp;&nbsp;{t('contactPage.sending')}...
                    </>
                  ) : (
                      <>{t('contactPage.sendMessage')}</>
                  )}
                </Button>
                <p className="mt-3 font-italic">
                  {/* This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" aria-label="Google Policy">{t('navigation.privacy')}</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer" aria-label="Google Policy">Terms of Service</a> apply. */}
                  {t('contactPage.agreement')}{' '}
                  <LocalizedLink to="/privacy">{t('navigation.privacy')}</LocalizedLink>
                </p>
              </Form>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
